import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4518eb97"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "main"
};
var _hoisted_2 = {
  class: "rank"
};
var _hoisted_3 = {
  class: "desc"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "name"
};
var _hoisted_6 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arr, function (item) {
    return _openBlock(), _createElementBlock("div", {
      key: item,
      class: "item"
    }, [_createElementVNode("div", _hoisted_2, _toDisplayString(item.rank), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
      src: item.src,
      class: "pic"
    }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1)]), _createElementVNode("a", {
      href: item.href,
      class: "href",
      target: "_blank"
    }, "立即跳转", 8, _hoisted_6)]);
  }), 128))]);
}