import { defineComponent, ref, onMounted } from 'vue';
export default defineComponent({
  name: '',
  setup: function setup() {
    var arr = ref([{
      rank: 1,
      src: "https://bcepd-dev.atuniversity.cn/z_res/paintbook/jd.png",
      name: "国文obook旗舰店",
      href: "https://item.jd.com/10061722771384.html"
    }, {
      rank: 2,
      src: "https://bcepd-dev.atuniversity.cn/z_res/paintbook/tm.png",
      name: "天猫obook旗舰店",
      href: "https://detail.tmall.com/item.htm?abbucket=8&id=673370799049&rn=0f588731153988bc8fb2a1d6ad400dd4&spm=a1z10.5-b-s.w4011-18884002292.75.c9e31ae3IfmHfL&sku_properties=5919063:6536025"
    }, {
      rank: 3,
      src: "https://bcepd-dev.atuniversity.cn/z_res/paintbook/tm.png",
      name: "天猫拾墨旗舰店",
      href: "https://detail.tmall.com/item.htm?id=672679118583&scene=taobao_shop&spm=a1z10.1-b-s.w5003-22940586912.1.3bc46124Wz5gqi&skuId=4892397240916"
    }, {
      rank: 4,
      src: "https://bcepd-dev.atuniversity.cn/z_res/paintbook/tb.png",
      name: "淘宝易连聚官方店",
      href: "https://item.taobao.com/item.htm?spm=a1z10.5-c-s.w4002-21359729539.10.25125cba4mIqtT&id=690490108557"
    }, {
      rank: 5,
      src: "https://bcepd-dev.atuniversity.cn/z_res/paintbook/wx.png",
      name: "百城mall官方小程序",
      href: "https://bcepd-dev.atuniversity.cn/z_res/paintbook/mini.png"
    }]);
    var url = 'https://bcepd-dev.atuniversity.cn/z_res/paintbook/jump-er.png';
    var srcList = ['https://bcepd-dev.atuniversity.cn/z_res/paintbook/mini.png'];
    onMounted(function () {
      window.scrollTo(0, 0);
    });
    return {
      arr: arr,
      url: url,
      srcList: srcList
    };
  }
});